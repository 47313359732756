/** entry point of CRM module*/
(function()
{
	var NAME = "et.evenue.Module";

	var C = et.constants;
	et.module.create(NAME,
	{
		onLoad : function()
		{
			//p_container.height($(window).height());
			//p_container.width($(window).width());

			//check siteId
            var siteId = et.getPageParameter(et.constants.SITEID);
            //Get zookeeper configs - only if sessionStorage zkpConfig isn't defined
            var zkpConfigStr = sessionStorage.getItem('zkpConfig_'.concat(et.getPageParameter(et.constants.LINKID)));
            if (!zkpConfigStr) {
                //PAC-17831 - need to use linkID for dual-language sites (i.e fta and fta-esp)
                //since siteId for fta and fta-esp is the same
                //linkID should always be defined in the url
                var siteIdFromLinkId = 'ev_' + et.getPageParameter(et.constants.LINKID);
                var zkpConfig = {};
                $.ajax({
                    url: '/pac-api/catalog/evenueconfig',
                    method: 'GET',
                    headers: {
                        siteid: siteIdFromLinkId
                    }
                }).done(function(resp) {
                    zkpConfig = resp;
                }).fail(function() {
                    console.error('Failed to retrieve zkp configs!');
                }).always(function() {
                    sessionStorage.setItem('zkpConfig_'.concat(et.getPageParameter(et.constants.LINKID)), JSON.stringify(zkpConfig));
                    $(document).trigger('evenueconfig.loaded');
                });
            }

			$(document).on("click", "a", this.href, function(e)
			{
				var rawHref = e.currentTarget.getAttribute("href");
				//if (rawHref.match(/^#.*/))
				if (rawHref && rawHref.match(/^#.*/))
				{
					e.currentTarget.href = et.createUrl(e.currentTarget.href, et.DefaultPageParams);
				}
			});


			var rsrc = et.getQueryParameter("RSRC");
			var rdat = et.getQueryParameter("RDAT");
			if(rsrc)
			{
				$.cookie("referral_id", rsrc, {
					path : "/"
				});
			}
			if(rdat)
			{
				$.cookie("referral_dat", rdat, {
					path : "/"
				});
			} 


			//parse the default params
			et.DefaultPageParams =
			{
			};

			et.siteId = et.DefaultPageParams[C.SITEID] = et.getPageParameter(C.SITEID);
			et.locale = et.DefaultPageParams[C.LOCALE] = et.getPageParameter(C.LOCALE);
			et.evLinkId = et.DefaultPageParams[C.LINKID] = et.getPageParameter(C.LINKID);

			//save for reference, pageControler will use this to decide if the page needs to be re-laoded.
			$.cookie(et.cookiePrefix + C.SITEID, et.siteId,
			{
				path : et.cookiePath
			});
			$.cookie(et.cookiePrefix + C.LINKID, et.evLinkId,
			{
				path : et.cookiePath
			});
			
			//load customization if present
			if (et.siteId)
			{  
				//load site css
				et.loadCSS(["customize/" + et.siteId + "/css/site.css"]);

				//resolve sellerId/poolId
				var defer = et.resolveSiteId(et.siteId);
				defer.done(function(p_siteId, p_sellerId, p_poolId, p_locale, p_locales, p_busOrgVer)
				{
					et.poolId = p_poolId;
					et.busOrgVer = p_busOrgVer;

					setLocale(p_locale, p_locales);
					
					//check if there is staging variable for webdev
					var ev_stagingSession = JSON.parse(sessionStorage.getItem('ev_ENV'));

					//initial staging path
					var ev_stagingScriptPath = '';
					
					if(typeof ev_stagingSession !== 'undefined' && ev_stagingSession === 'staging')
					{
						ev_stagingScriptPath = 'customize/' + ev_stagingSession + '/' + et.siteId + '/script';
					}
					else
					{
						ev_stagingScriptPath = 'customize/' + et.siteId + '/script';
					}

					et.loadScript([[ev_stagingScriptPath + '/override.js?_=' + Date.now()]], function()
					{
						et.evenue.Module.load();

						// DISC-362 (load browser requirements from AWS)
						var browserReqLoaded = setInterval(function() {
							if($('#browserReq').length>0) {
								$('#browserReq').load(
									'https://pachtml-production.s3.us-west-2.amazonaws.com/global/info/browserReq.html'
								);
								clearInterval(browserReqLoaded);
							}
						}, 100);
					});

				});
				defer.fail(function()
				{
					setLocale();
					et.evenue.Module.load();

				});
				//PAC-17772 -  make sso call everytime to make sure updating session Storage
                if(sessionStorage.getItem("ev_sh_"+et.evLinkId)===null || sessionStorage.getItem("ev_sh_"+et.evLinkId) == ''){
                    $.ajax({
                        url: "https://" + window.location.hostname + "/pac-api/accounts/sso/siteId/" +et.siteId,
                        dataType: "json",
                        async: true,
                        success: function(data) {
                            window.ev_sh = {
                                status:false,
                                link:''
                            }
                            
                            if(data.status.cd===0){
                                sessionStorage.setItem("ev_sh_"+et.evLinkId, data.result.sso); 
                                sessionStorage.setItem("ev_shLink_"+et.evLinkId, data.result.ssolink+"?linkID="+et.evLinkId); 
                                sessionStorage.setItem("ev_shLinkOut_"+et.evLinkId, data.result.logoutUrl);
                                window.ev_sh = {
                                    status: data.result.sso,
                                    link:data.result.ssolink+"?linkID="+et.evLinkId,
                                    linkOut:data.result.logoutUrl
                                }
                            } 
                        }
                    });
                } 
                //end PAC-17772 
			}
			else
			{
				setLocale();
				et.evenue.Module.load();
			}
		},

		load : function()
		{
			$("body").load("global/view/layout.html?v=" + et.version, function()
			{

				et.loadScript([["global/controller/pageController.js", "global/controller/headController.js", "global/controller/footerController.js"]], function()
				{
					et.evenue.HeadController.bind($("#header"));
					//this can be replaced by customized  header
					//et.evenue.NavBarController.bind($("#navbar"));//this can be replaced by customized  navbar
					et.evenue.PageController.bind($("#workspace"));
					
					et.evenue.FooterController.bind($("#footer"));
					
					et.onModuleLoaded.fire();
				});
			});
		}
	});
	
	var setLocale = function(p_locale, p_locales)
	{
		//set default locale if absent
		et.locale =  et.locale || p_locale || "en_US";
		et.locales = p_locales;
		//load the resources (text, error messages)
		et.loadResources('res', 'errors_ss', 'syscodes_ss', et.locale);
		$.cookie(et.cookiePrefix + C.LOCALE, et.locale,
		{
			path : et.cookiePath
		});
	};

})();
